html {
  scroll-behavior: smooth;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #555;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 100;
}

body h1 {
  width: 100%;
  z-index: 6;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  margin-block-start: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  font-weight: 100;
  position: fixed;
}

@media only screen and (min-width: 375px) {
  body h1 {
    font-size: 1.5em;
  }
}

body #gallery {
  position: absolute;
  inset: 60px 30px 30px;
}

body #gallery .trombinoscope {
  width: 100%;
  height: 100%;
}

body #gallery .trombinoscope .wrapper {
  vertical-align: top;
  justify-content: center;
  margin: 5px;
  transition: transform .3s ease-in-out;
  display: inline-flex;
  position: relative;
  transform: translate3d(0, 0, 0);
}

body #gallery .trombinoscope .wrapper.out {
  transform: translate3d(0, -3000px, 0);
}

body #gallery .trombinoscope .wrapper .circle {
  pointer-events: none;
  max-height: max-content;
  background-color: #efefef;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 5px #0003;
}

body #gallery .trombinoscope .wrapper .circle .image {
  width: 100%;
  height: 100%;
  mix-blend-mode: darken;
  pointer-events: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.shield {
  z-index: 4;
  position: fixed;
  inset: 0;
}

.projets {
  z-index: 5;
  position: absolute;
  inset: 60px 30px 30px;
}

.projets .projectWrapper {
  height: 100%;
  margin-bottom: 30px;
  position: relative;
}

.projets .projectWrapper .iframeWrapper {
  height: 100%;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 30px;
  scroll-margin-top: 60px;
  position: relative;
}

.projets .projectWrapper .iframeWrapper .codeIcon {
  width: 40px;
  height: 40px;
  color: #333;
  z-index: 20;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 2px;
  right: 2px;
}

.projets .projectWrapper .iframeWrapper .tabLayer {
  z-index: 10;
  height: 100%;
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
}

.projets .projectWrapper .iframeWrapper .tabLayer.active {
  display: block;
}

.projets .projectWrapper .iframeWrapper .tabLayer .code-viewer {
  border: .25px solid #333;
  margin-bottom: 30px;
  padding-top: 30px;
  position: absolute;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.projets .projectWrapper .iframeWrapper .tabLayer .code-viewer h1 {
  width: 100px;
  cursor: pointer;
  background-color: #f5f5f5;
  border-right: .25px solid #333;
  margin-top: -30px;
  padding-left: 10px;
  font-size: 13px;
  position: absolute;
}

.projets .projectWrapper .iframeWrapper .tabLayer .code-viewer h1.active {
  background-color: #fff !important;
}

.projets .projectWrapper .iframeWrapper .tabLayer .code-viewer:last-child h1 {
  background-color: #fff;
}

.projets .projectWrapper .iframeWrapper iframe {
  border: none;
  border-radius: 8px;
  margin-bottom: 30px;
  display: block;
}

.projets .projectWrapper .iframeWrapper iframe:last-child {
  display: unset;
  margin-bottom: 26px;
}

.bounceInDown {
  animation-name: bounceInDown;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  100% {
    transform: none;
  }
}

.rubberBand {
  animation-name: rubberBand;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes slideInUp {
  0% {
    visibility: visible;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

/*# sourceMappingURL=index.95f81ff9.css.map */
