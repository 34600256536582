html {
  scroll-behavior: smooth;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
  // margin:0px;
  // padding:30px;
  // padding-left:30px;
  color: #555;
  //   iframe {
  //     position: fixed;
  //     top: 0px;
  //     background-color: white;
  //     border: none;
  //   }
  h1 {
    position: fixed;
    width: 100%;
    z-index: 6;
    background-color: #fff;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 100;
    /* line-height: 20px; */
    margin-block-start: 0;
    cursor: pointer;
    user-select: none;
    @media only screen and (min-width: 375px) {
      font-size: 1.5em;
    }
  }
  #gallery {
    position: absolute;
    // border: solid 1px #ddd;
    // border-radius: 12px;
    top: 60px;
    left: 30px;
    bottom: 30px;
    right: 30px;
    // background-color: #efefef;

    .trombinoscope {
      width: 100%;
      height: 100%;

      // display: flex;
      .wrapper {
        position: relative;
        display: inline-flex;
        justify-content: center;
        margin: 5px;
        vertical-align: top;
        transform: translate3d(0px, 0px, 0px);
        transition: transform 0.3s ease-in-out;

        &.out {
          transform: translate3d(0, -3000px, 0);
        }

        .circle {
          border-radius: 50%;
          overflow: hidden;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          background-color: #efefef;
          //   margin-top: -100px;
          pointer-events: none;
          max-height: max-content;

          .image {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            mix-blend-mode: darken;
            pointer-events: none;
          }
        }
      }
    }
  }
}

.shield {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 4;
}

.projets {
  position: absolute;
  top: 60px;
  left: 30px;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  .projectWrapper {
    position: relative;
    height: 100%;
    margin-bottom: 30px;
    .iframeWrapper {
      position: relative;
      height: 100%;
      width: 100%;
      margin-bottom: 30px;
      scroll-margin-top: 60px;
      padding-bottom: 30px;
      // overflow: hidden;
      // display: block;

      .codeIcon {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: white;
        color: #333;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        opacity: 0.5;
      }
      .tabLayer {
        // background-color: red;
        position: absolute;
        top: 0px;
        z-index: 10;
        height: 100%;
        width: 100%;
        display: none;
        &.active {
          display: block;
        }

        .code-viewer {
          // border-radius: 8px;
          position: absolute;
          top: 0px;
          border: 0.25px solid #333;
          overflow: hidden;
          overflow-y: scroll;
          padding-top: 30px;
          margin-bottom: 30px;
          // position: absolute;
          // top: 30px;
          // z-index: 10;
          h1 {
            margin-top: -30px;
            font-size: 13px;
            padding-left: 10px;
            width: 100px;
            border-right: 0.25px solid #333;
            position: absolute;
            background-color: #f5f5f5;
            cursor: pointer;
            &.active {
              background-color: #fff !important;
            }
          }
          &:last-child {
            h1 {
              background-color: #fff;
            }
          }
        }
      }
      iframe {
        border: none;
        margin-bottom: 30px;
        border-radius: 8px;
        display: block;
        margin-bottom: 30px;
        &:last-child {
          display: unset;
          margin-bottom: 26px;
        }
      }
    }
  }
}

.headFace {
}
